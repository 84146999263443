import {
  CLEAR_DATA,
  SAVE_DATA,
  SAVE_OPTIONS_RESPONSE,
  SAVE_SELECTED_OBJECT,
} from "./actionTypes";

const init = {
  refFinderData: [],
  selectedObject: {},
  businesses: [],
  products: [],
  states: [],
};
const repFinderReducer = function (state = init, action) {
  switch (action.type) {
    case SAVE_DATA:
      return { ...state, refFinderData: action.payload };
    case CLEAR_DATA:
      return init;
    case SAVE_SELECTED_OBJECT:
      return { ...state, selectedObject: action.payload };
    case SAVE_OPTIONS_RESPONSE:
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }
};
export default repFinderReducer;
