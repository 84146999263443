import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { createStore } from "redux";

import "./App.css";
import repFinderReducer from "./Redux/reducer";
import Loading from "./static/images/loadingui.webp";
const ErrorBoundary = React.lazy(() => import("./ErrorHandlers/ErrorBoundary"));
const RepFinderContainer = React.lazy(() =>
  import("./Containers/RepFinderContainer")
);
const RepFinderheader = React.lazy(() =>
  import("./Containers/RepFinderheader")
);
let store = createStore(repFinderReducer);
const loaderStyles = { width: "70vw", height: "90vh" };
function App() {
  return (
    <Provider store={store}>
      <section className="App">
        <Suspense
          fallback={
            <h1>
              <img
                src={Loading}
                alt="Loading Rep Finder UI..."
                style={loaderStyles}
              />
            </h1>
          }
        >
          <ErrorBoundary>
            <RepFinderheader />
            <RepFinderContainer />
          </ErrorBoundary>
        </Suspense>
      </section>
    </Provider>
  );
}

export default App;
